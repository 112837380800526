import * as React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'gatsby'

import {
  withPrismicUnpublishedPreview,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews'
import { linkResolver } from '../utils/LinkResolver'
import HomeTemplate from '../templates/homepage'

const NotFoundPage = () => {
  return (
    <section className="jumbotron">
      <Container>
        <h1 className="text-center mt-5 p-5">
          Sorry, this path does not exist
          <span role="img" aria-label="emoji">
            😞
          </span>
        </h1>
        <p className="justify-content-center text-center">
          <Link to="/">
            <span>Go Home</span>
          </Link>
        </p>
      </Container>
    </section>
  )
}

export default withPrismicUnpublishedPreview(NotFoundPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
    componentResolver: componentResolverFromMap({
      homepage: HomeTemplate,
    }),
  },
])
